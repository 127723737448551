import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import SinglePortraitImageWithTitle from "../components/SinglePortraitImageWithTitle"
import ImageGrid from "../components/ImageGrid"

function mintAndJuniper({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.mintAndJuniper.coverCap")}}
                  title={t("items.mintAndJuniper.title")}>
    <Paragraph>{t("items.mintAndJuniper.p1")}</Paragraph>
    <ImageWithTitle fluid={data.mintAndJuniper1.childImageSharp.fluid} title={t("items.mintAndJuniper.cap1")}/>
    <Paragraph>{t("items.mintAndJuniper.p2")}</Paragraph>
    <Paragraph>{t("items.mintAndJuniper.p3")}</Paragraph>
    <SinglePortraitImageWithTitle fluid={data.mintAndJuniper2.childImageSharp.fluid} title={t("items.mintAndJuniper.cap2")} />
    <ImageGrid>
      <ImageWithTitle fluid={data.grid1.childImageSharp.fluid} title={t("items.mintAndJuniper.cap3")}/>
      <ImageWithTitle fluid={data.grid2.childImageSharp.fluid} title={t("items.mintAndJuniper.cap4")}/>
      <ImageWithTitle fluid={data.grid3.childImageSharp.fluid} title={t("items.mintAndJuniper.cap5")}/>
      <ImageWithTitle fluid={data.grid4.childImageSharp.fluid} title={t("items.mintAndJuniper.cap6")}/>
      <ImageWithTitle fluid={data.grid5.childImageSharp.fluid} title={t("items.mintAndJuniper.cap7")}/>
      <ImageWithTitle fluid={data.grid6.childImageSharp.fluid} title={t("items.mintAndJuniper.cap8")}/>
    </ImageGrid>
    <SinglePortraitImageWithTitle fluid={data.mintAndJuniper3.childImageSharp.fluid} title={t("items.mintAndJuniper.cap9")} />
  </Article>
}

export default i18nHOC(mintAndJuniper);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "mint-and-juniper/IMG_9870.jpg" }) {
        ...CoverImage
    },
    mintAndJuniper1: file(relativePath: { eq: "mint-and-juniper/DSC_2630 (1).JPG" }) {
      ...FluidArticleImage
    },
    mintAndJuniper2: file(relativePath: { eq: "mint-and-juniper/DSC08025.JPG" }) {
       ...FluidArticleImage
    },
    grid1: file(relativePath: { eq: "mint-and-juniper/DSC_2512.JPG" }) {
      ...FluidArticleImage
    },
    grid2: file(relativePath: { eq: "mint-and-juniper/DSC_2092.JPG" }) {
      ...FluidArticleImage
    },
    grid3: file(relativePath: { eq: "mint-and-juniper/DSC_2619.JPG" }) {
      ...FluidArticleImage
    },
    grid4: file(relativePath: { eq: "mint-and-juniper/DSC_1737.JPG" }) {
      ...FluidArticleImage
    },
    grid5: file(relativePath: { eq: "mint-and-juniper/DSC08035.JPG" }) {
      ...FluidArticleImage
    },
    grid6: file(relativePath: { eq: "mint-and-juniper/DSC08038.JPG" }) {
      ...FluidArticleImage
    },
    mintAndJuniper3: file(relativePath: { eq: "mint-and-juniper/IMG_20170817_121414_HDR.jpg" }) {
      ...FluidArticleImage
    }
  }
`